@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
@tailwind screens;

@layer components {
    .calc-button {
        @apply text-white cursor-pointer w-[16vw] h-[16vh] text-[8vh] rounded-md drop-shadow-lg;
    }

    .common-calc-button {
        @apply bg-common-button;
    }

    .special-calc-button {
        @apply bg-special-button;
    }

    .hovered-calc-button {
        @apply hover:bg-hovered-button hover:rounded-lg hover:drop-shadow-2xl;
    }

    .highlighted-text {
        @apply text-highlighted-red italic
    }
}
